// colors
$black: #111626;
$dark-blue: #2a324b;
$blue: #203d52;
$light-blue: #496a81;
$grey: #767b91;
$off-white-dark: #c7ccdb;
$off-white-light: #e1e5ee;
$white: #fff;

// font size
$font-small: 1.4rem;
$font-regular: 2.6rem;
$font-large: 4.5rem;

// spacing
$xs-size: 0.75rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 60rem;
