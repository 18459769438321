.carousel {
  padding-top: 3rem;
  padding-bottom: -3rem;

  .thumbs-wrapper {
    display: flex;
    justify-content: center;
  }
}

.carousel-container {
  max-width: 100%;

  @media (min-width: $desktop-breakpoint) {
    max-width: 80%;
  }
}

// scroll-to-top
.scroll-top {
  position: fixed;
  bottom: 30%;
  right: 5%;
}

.scroll-top-btn {
  visibility: hidden;
  border: none;
  background-color: #2a324b;
  color: #ffffff;
  border-radius: 50%;
  box-shadow: 3px 3px 3px #dddddd;
  padding: 1rem 1.2rem;
  cursor: pointer;
  transition: visibility 0s, opacity 0.2s ease-in-out;
  opacity: 0;
}

.scroll-top-btn:hover {
  opacity: 0.8;
}

button:focus {
  outline: 0;
}
