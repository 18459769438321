@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Nunito:wght@200;300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 40%;
  scroll-behavior: smooth;
  overflow-x: hidden !important;

  @media (min-width: $desktop-breakpoint) {
    font-size: 62.5%;
  }
}

body {
  font-family: 'Nunito', 'Sans Serif';
  background: $dark-blue;
}

h1,
h2 {
  font-family: 'Montserrat', 'Sans Serif';
  font-weight: 600;
}

h1 {
  font-size: 4.8rem;
}

h2 {
  font-size: 3.6rem;
}

h3 {
  font-size: 3.2rem;
}

h4 {
  font-size: 2.8rem;
}

.row {
  margin: 0;
}

.animate-1,
.animate-2 {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
}
