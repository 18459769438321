.work {
  background: $off-white-dark;
  padding-top: 5rem;
  padding-bottom: 5rem;

  h1 {
    color: $dark-blue;
    font-weight: 700;
  }

  h4 {
    color: $grey;
    font-weight: 400;
  }

  .btn--view {
    background: $grey;
    border: none;
    border-radius: 5px;
    color: $white;
    display: none;
    font-size: 2em;
    margin-top: 2rem;
    padding: $xs-size $m-size;
    transition: all 0.15s ease-in-out;

    &:hover {
      cursor: pointer;
      background: darken($grey, 5%);
    }
  }
}

.work__github {
  margin-top: $l-size;
  text-align: center;

  p {
    color: $grey;
    font-size: $font-regular - 0.75rem;
    font-weight: 400;
    margin-bottom: $xs-size;
  }
  a {
    color: $dark-blue;
    font-size: $font-regular;
    font-weight: 500;
    transition: all 0.1s ease-in-out;

    &:hover {
      color: $light-blue;
      text-decoration: none;
    }
  }
}
