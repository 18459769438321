.contact {
  background-color: $white;
  padding-top: 5rem;
  padding-bottom: 1rem;

  h1 {
    color: $light-blue;
    margin-bottom: $m-size;
    @media (min-width: $desktop-breakpoint) {
      margin-bottom: $l-size;
    }
    img {
      filter: invert(36%) sepia(54%) saturate(306%) hue-rotate(161deg) brightness(96%) contrast(88%);
      height: 50px;

      @media (min-width: $desktop-breakpoint) {
        height: 70px;
      }
    }
  }
}

.contact-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: $xs-size;

  .cnt-label {
    color: #496a81;
    display: block;
    font-size: $font-regular;
    word-break: break-all;
  }

  .cnt-val {
    display: block;
    color: #767b91;
    font-size: 3.8rem;
    margin-bottom: $l-size;
    word-break: break-all;

    @media (min-width: $desktop-breakpoint) {
      display: inline-block;
      margin-bottom: 0;
    }
  }

  @media (min-width: $desktop-breakpoint) {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: $xl-size;
  }
}

.social {
  cursor: pointer;
  filter: invert(55%) sepia(12%) saturate(610%) hue-rotate(191deg) brightness(85%) contrast(82%);
  height: 30px;
  margin: $m-size $xs-size;
  transition: all 0.15s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
}

.footer {
  color: $grey;
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}
